<template>
  <div>
    <v-row class="mx-0"><!-- mb-6  -->
      <v-flex v-if="$route.path !== '/dashboard'" class="text-left xs1" style="padding: 1.4vh 0 !important;">
        <v-btn fab icon :color="$route.path !== '/dashboard' ? 'rgba(0, 0, 0, 0.54)' : 'transparent'" @click="goBack" small>
          <v-icon>{{ 'mdi-arrow-left-bold' }}</v-icon>
        </v-btn>
      </v-flex>
      <v-flex class="mt-1" :class="timerData.isTimeRunning && $route.path !== '/stemp'  ? $route.path === '/dashboard' ? 'xs8' : 'xs7' :  $route.path === '/dashboard' ? 'xs11' : 'xs10'">
        <v-layout justify-center>
          <v-img height="40" contain :src="require(`../assets/${logoUrl}`)" @click="navigateToDashboard"></v-img>
        </v-layout>
        <!-- :href="`https://${getAppHost}`" target="_blank" --><a style="text-decoration:none" @click="navigateToDashboard"><p class="text-center mt-0 orange--text mb-0 font-weight-bold text-body-2">{{getAppHost}}</p></a>
      </v-flex>
      <v-flex class="text-right" :class="timerData.isTimeRunning && $route.path !== '/stemp' ? 'xs3 pr-1' : ''">
        <timer v-if="isAuthenticated && $route.path !== '/stemp'"></timer>
      </v-flex>
      <v-flex xs1 class="my-auto" v-if="checkFeatureEnabled('tasks')">
        <router-link to="/tasks">
          <v-badge left :content="tasksCount" overlap>
            <v-icon>mdi-calendar-check</v-icon>
          </v-badge>
        </router-link>
      </v-flex>
    </v-row>
    <v-divider class="mx-1 grey darken-1"></v-divider>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FeaturesMixin from '@/mixins/features_list'
export default {
  mixins: [FeaturesMixin],
  data () {
    return {
      logoUrl: process.env.VUE_APP_LOGO_FILE_NAME,
      tasksCount: 'test'
    }
  },
  components: {
    timer: () => import('../components/NavbarTimer')
  },
  created () {
    this.tasksCount = window.localStorage.getItem(process.env.VUE_APP_TASKS_COUNT) || '0'
    // this.tasksCount = this.$cookie.get(process.env.VUE_APP_TASKS_COUNT) || '0'
  },
  methods: {
    navigateToDashboard () {
      if (this.$route.path !== '/dashboard') this.$router.push('/dashboard')
    },
    goBack () {
      const path = this.$route.path
      if (['/equipments', '/buildings', '/timer'].includes(path)) this.$router.push('/dashboard')
      else this.$router.go(-1) // this.$eventBus.$emit('goToPreviousPage')
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'timerData']),
    getAppHost () {
      return this.$cookie.get('AxentivPwa_host-ref')
    }
  }
}
</script>

<style scoped>
</style>
